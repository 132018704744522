<template>
  <form
    class="dropdown d-inline"
    @submit.prevent
  >
    <material-button
      color="secondary"
      variant="outline"
      class="dropdown-toggle"
      :class="{
        show: open,
      }"
      :aria-expanded="open"
      :disabled="disabled"
      @click="open = !open"
    >
      {{ name }}
    </material-button>

    <ul
      class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
      :class="{
        show: open,
      }"
    >
      <li
        v-for="[optionValue, label] in options"
        :key="label"
        class="dropdown-item border-radius-md"
      >
        <material-checkbox
          :id="`${name}-${label}`"
          v-model="value"
          :name="name"
          :value="optionValue"
        >
          {{ label }}
        </material-checkbox>
      </li>
    </ul>
  </form>
</template>

<script>
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialCheckbox from '@/components/MaterialCheckbox.vue';

export default {
  name: 'CheckboxFilter',

  components: {
    MaterialButton,
    MaterialCheckbox,
  },

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Map,
      default: new Map(),
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  emits: [
    'update:modelValue',
  ],

  data: () => ({
    open: false,
  }),

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style scoped>
.dropdown-menu::before {
  content: none;
}

.dropdown-toggle::after {
  content: "\e5c7";
  font: normal normal normal 14px/1 "Material Icons Round";
  font-weight: normal;
  border: none;
  vertical-align: middle;
  font-weight: 600;
}
</style>